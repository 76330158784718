import React, { createContext, useContext } from 'react';
import { styled } from '@fis/util-theme';

/* -------------------------------------------------------------------------------------------------
 * LevelContext
 * -----------------------------------------------------------------------------------------------*/

const LevelContext = createContext(1);
LevelContext.displayName = 'LevelContext';

/* -------------------------------------------------------------------------------------------------
 * useLevel
 * -----------------------------------------------------------------------------------------------*/

const useLevel = () => useContext(LevelContext);

/* -------------------------------------------------------------------------------------------------
 * Level
 * -----------------------------------------------------------------------------------------------*/

type LevelProps = {
  value?: number;
  children: React.ReactNode;
};

const Level = ({ children, value }: LevelProps) => {
  const contextLevel = useContext(LevelContext);
  const level = value ?? contextLevel + 1;

  return (
    <LevelContext.Provider value={level}>{children}</LevelContext.Provider>
  );
};

// ============================================================================================

/* -------------------------------------------------------------------------------------------------
 * DynamicHeading
 * -----------------------------------------------------------------------------------------------*/

// Set as h1 to receive heading-specific props typechecking
const DynamicHeading = styled('h1', {
  color: 'white',
  fontWeight: '$bold',

  '.lightMode &': {
    color: '$gray700',
  },

  variants: {
    styling: {
      xxxl: {
        fontFamily: '$financier',
        fontWeight: '$semibold',
        fontSize: '$100',
        lineHeight: '3.25rem',
        letterSpacing: '1px',
      },
      xxl: {
        // Use this for page headings
        fontFamily: '$financier',
        fontWeight: '$semibold',
        fontSize: '$90',
        lineHeight: '2.5rem',
        letterSpacing: '1px',
        marginBottom: '.5rem',
      },
      xl: {
        fontSize: '$82',
        lineHeight: '2rem',
        fontFamily: '$firaSans',
        fontWeight: '$bold',
      },
      lg: {
        fontSize: '$60',
        lineHeight: '1.625rem',
        fontFamily: '$firaSans',
        fontWeight: '$bold',
      },
      med: {
        fontSize: '$30',
        lineHeight: '1.3rem',
        fontFamily: '$firaSans',
        fontWeight: '$semibold',
      },
      sm: {
        fontSize: '$20',
        lineHeight: '1rem',
        fontFamily: '$firaSans',
        fontWeight: '$semibold',
      },
    },
    faded: {
      true: {
        color: '$gray400',

        '.lightMode &': {
          color: '$gray500',
        },
      },
    },
  },
});

/* -------------------------------------------------------------------------------------------------
 * Heading
 * -----------------------------------------------------------------------------------------------*/

type HeadingLevel = 1 | 2 | 3 | 4 | 5 | 6;
type Props = React.ComponentPropsWithRef<typeof DynamicHeading> & {
  readonly as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  readonly level?: HeadingLevel;
  readonly offset?: Exclude<HeadingLevel, 6>;
};

const Heading = React.forwardRef<HTMLHeadingElement, Props>(
  ({ children, level, offset, styling, ...rest }, ref) => {
    const contextLevel = useLevel();
    const headingLevel = level
      ? `h${level}`
      : `h${offset ? contextLevel + offset : contextLevel}`;

    return (
      <DynamicHeading as={headingLevel} ref={ref} {...rest} styling={styling}>
        {children}
      </DynamicHeading>
    );
  }
);

Heading.displayName = 'Heading';

export { Heading, Level, useLevel };
